var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"新增套餐"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"custom-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"套餐名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入套餐名称' },
              { max: 100, message: '最多100个字符' } ]
          }]),expression:"['name', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入套餐名称' },\n              { max: 100, message: '最多100个字符' },\n            ]\n          }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"项目代码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['code', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入项目代码' },
              { max: 20, message: '最多20个字符' } ]
          }]),expression:"['code', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入项目代码' },\n              { max: 20, message: '最多20个字符' },\n            ]\n          }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"拼音码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['pinyin', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入拼音码' },
              { max: 20, message: '最多20个字符' } ]
          }]),expression:"['pinyin', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入拼音码' },\n              { max: 20, message: '最多20个字符' },\n            ]\n          }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"所属殡仪馆"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['funeral_home_ids', {
            rules: [{ required: true, message: '请选择所属殡仪馆' }]
          }]),expression:"['funeral_home_ids', {\n            rules: [{ required: true, message: '请选择所属殡仪馆' }]\n          }]"}],attrs:{"loading":_vm.loadingFuneralHomeOptions,"mode":"multiple"},on:{"focus":_vm.fetchFuneralHomeOptions,"change":_vm.handleChangeFuneralHome,"deselect":_vm.handleDeselectFuneralHome}},_vm._l((_vm.funeralHomeOptions),function(home){return _c('a-select-option',{key:home.id,attrs:{"value":home.id}},[_vm._v(" "+_vm._s(home.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"关联厅房"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['hall_ids', {
            rules: [{ required:true,message: '请选择厅房' }],
          }]),expression:"['hall_ids', {\n            rules: [{ required:true,message: '请选择厅房' }],\n          }]"}],attrs:{"mode":"multiple"}},_vm._l((_vm.hallOptions),function(hall){return _c('a-select-option',{key:hall.id,attrs:{"value":hall.id}},[_vm._v(" "+_vm._s(hall.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"价格(元)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['price', {
            rules: [
              { required: true, message: '请输入价格' } ]
          }]),expression:"['price', {\n            rules: [\n              { required: true, message: '请输入价格' },\n            ]\n          }]"}],staticStyle:{"width":"100%"},attrs:{"min":0.00,"max":10000000,"step":0.01,"precision":2}})],1),_c('a-form-item',{attrs:{"label":"图片"}},[_c('div',{staticStyle:{"line-height":"20px","margin-top":"10px","margin-bottom":"10px"}},[_vm._v("支持png/jpg图片格式，建议尺寸宽高比180:180px，最多1张 ")]),_c('upload-image',{directives:[{name:"decorator",rawName:"v-decorator",value:(['attachments',{
            rules:[
              {validator: _vm.checkAttachments}
            ]
          }]),expression:"['attachments',{\n            rules:[\n              {validator: checkAttachments}\n            ]\n          }]"}],attrs:{"is-add-watermark":_vm.isAddWatermark,"accept":".jpg,.jpeg,.png","max-count":1},on:{"change":_vm.handleUploadImage}})],1),_c('a-form-item',{attrs:{"label":"详情图片"}},[_c('div',{staticStyle:{"line-height":"20px","margin-top":"10px","margin-bottom":"10px"}},[_vm._v("支持png/jpg图片格式，建议尺寸宽高比180:180px，最多5张 ")]),_c('upload-image',{directives:[{name:"decorator",rawName:"v-decorator",value:(['detail_attachments',{
            rules:[
              {validator: _vm.checkDetailAttachments}
            ]
          }]),expression:"['detail_attachments',{\n            rules:[\n              {validator: checkDetailAttachments}\n            ]\n          }]"}],attrs:{"is-add-watermark":_vm.isAddWatermark,"accept":".jpg,.jpeg,.png","max-count":5,"multiple":true},on:{"change":_vm.handleUploadDetailImage}})],1),_c('a-form-item',{attrs:{"label":"介绍"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remark', {
            normalize: this.$lodash.trim,
            rules: [
              { max: 300, message: '最多300个字符' } ]
          }]),expression:"['remark', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { max: 300, message: '最多300个字符' },\n            ]\n          }]"}]})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }