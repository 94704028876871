<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="新增套餐"
      @ok="handleSubmit"
    >
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item label="套餐名称">
          <a-input
            v-decorator="['name', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入套餐名称' },
                { max: 100, message: '最多100个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="项目代码">
          <a-input
            v-decorator="['code', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入项目代码' },
                { max: 20, message: '最多20个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="拼音码">
          <a-input
            v-decorator="['pinyin', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入拼音码' },
                { max: 20, message: '最多20个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="所属殡仪馆">
          <a-select
            :loading="loadingFuneralHomeOptions"
            mode="multiple"
            @focus="fetchFuneralHomeOptions"
            @change="handleChangeFuneralHome"
            @deselect="handleDeselectFuneralHome"
            v-decorator="['funeral_home_ids', {
              rules: [{ required: true, message: '请选择所属殡仪馆' }]
            }]"
          >
            <a-select-option
              v-for="home in funeralHomeOptions"
              :key="home.id"
              :value="home.id"
            >
              {{ home.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="关联厅房">
          <a-select
            mode="multiple"
            v-decorator="['hall_ids', {
              rules: [{ required:true,message: '请选择厅房' }],
            }]"
          >
            <a-select-option
              v-for="hall in hallOptions"
              :key="hall.id"
              :value="hall.id"
            >
              {{ hall.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="价格(元)">
          <a-input-number
            style="width: 100%;"
            :min="0.00"
            :max="10000000"
            :step="0.01"
            :precision="2"
            v-decorator="['price', {
              rules: [
                { required: true, message: '请输入价格' },
              ]
            }]"
          />
        </a-form-item>
        <a-form-item label="图片">
          <div
            style="line-height: 20px;margin-top: 10px;margin-bottom: 10px"
          >支持png/jpg图片格式，建议尺寸宽高比180:180px，最多1张
          </div>
          <upload-image
            :is-add-watermark="isAddWatermark"
            accept=".jpg,.jpeg,.png"
            :max-count="1"
            @change="handleUploadImage"
            v-decorator="['attachments',{
              rules:[
                {validator: checkAttachments}
              ]
            }]"
          />
        </a-form-item>
        <a-form-item label="详情图片">
          <div
            style="line-height: 20px;margin-top: 10px;margin-bottom: 10px"
          >支持png/jpg图片格式，建议尺寸宽高比180:180px，最多5张
          </div>
          <upload-image
            :is-add-watermark="isAddWatermark"
            accept=".jpg,.jpeg,.png"
            :max-count="5"
            :multiple="true"
            @change="handleUploadDetailImage"
            v-decorator="['detail_attachments',{
              rules:[
                {validator: checkDetailAttachments}
              ]
            }]"
          />
        </a-form-item>
        <a-form-item label="介绍">
          <a-textarea
            v-decorator="['remark', {
              normalize: this.$lodash.trim,
              rules: [
                { max: 300, message: '最多300个字符' },
              ]
            }]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import UploadImage from '@/components/Upload/Image'
import { createTenantPackage } from '@/api/package_management'
import { findHallWithFuneralHomeOptions } from '@/api/hall'
import { findFuneralHomeOptions } from '@/api/funeral_home'

export default {
  name: 'NewTenantPackage',
  components: {
    UploadImage
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'tenant_package_new' }),
      hallOptions: [],
      funeralHomeOptions: [],
      loadingFuneralHomeOptions: false,
      submitting: false,
      isAddWatermark: false,
      attachments: [], // 图片
      detailAttachments: [] // 详情图片
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
  },
  methods: {
    // 加载厅房选项
    fetchHallOptions(homeIds) {
      if (homeIds.length > 0) {
        findHallWithFuneralHomeOptions({ funeral_home_ids: homeIds }).then((res) => {
          this.hallOptions = res.data.options
        })
      } else {
        this.hallOptions = []
      }
    },

    fetchFuneralHomeOptions() {
      this.loadingFuneralHomeOptions = true
      findFuneralHomeOptions().then((res) => {
        this.funeralHomeOptions = res.data.options
      })
      this.loadingFuneralHomeOptions = false
    },

    handleChangeFuneralHome(homeIds) {
      const funeralHomeIds = this.form.getFieldValue('funeral_home_ids')
      if (!funeralHomeIds || (homeIds.length > funeralHomeIds.length)) {
        this.fetchHallOptions(homeIds)
      }
    },

    handleDeselectFuneralHome(homeId) {
      const hallIds = this.form.getFieldValue('hall_ids')
      const vm = this
      const funeralHomeIds = this.form.getFieldValue('funeral_home_ids')
      let isRelationHall = false
      this.$lodash.find(this.hallOptions, function(obj) {
        if (!isRelationHall && homeId === obj.funeral_home_id) {
          if (hallIds.includes(obj.id)) {
            vm.$warning({
              title: '当前殡仪馆被占用，请先解除相关厅房关联',
              content: ''
            })
            isRelationHall = true
          }
        }
      })
      if (isRelationHall) {
        this.$nextTick(() => {
          vm.form.setFieldsValue({ funeral_home_ids: funeralHomeIds })
        })
      }
      this.$nextTick(() => {
        this.fetchHallOptions(this.form.getFieldValue('funeral_home_ids'))
      })
    },

    handleUploadImage(fileList) {
      this.attachments = fileList
    },
    // 检查照片
    checkAttachments(rule, value, callback) {
      for (const key in this.attachments) {
        if (this.attachments[key] && this.attachments[key].status === 'error') {
          // eslint-disable-next-line standard/no-callback-literal
          callback('照片上传错误')
          return
        }
      }

      callback()
    },
    handleUploadDetailImage(fileList) {
      this.detailAttachments = fileList
    },
    // 检查详情照片
    checkDetailAttachments(rule, value, callback) {
      for (const key in this.detailAttachments) {
        if (this.detailAttachments[key] && this.detailAttachments[key].status === 'error') {
          // eslint-disable-next-line standard/no-callback-literal
          callback('照片上传错误')
          return
        }
      }

      callback()
    },
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      if (this.$lodash.find(this.attachments, { status: 'uploading' })) {
        this.$warning({
          title: '文件尚未上传完成',
          content: ''
        })
        return
      }
      if (this.$lodash.find(this.detailAttachments, { status: 'uploading' })) {
        this.$warning({
          title: '详情图片尚未上传完成',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            ...values,
            attachments: this.$lodash.map(this.attachments, 'response'),
            detail_attachments: this.$lodash.map(this.detailAttachments, 'response')
          }
          createTenantPackage(data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
